import { useEffect, useState } from 'react';
import './App.css';
import ServerStatus, { Serverstate } from './ServerStatus';


function App() {
  const [backendStatus, setBackendStatus] = useState<Serverstate>(Serverstate.loading);
  const [frontendStatus, setFrontendStatus] = useState<Serverstate>(Serverstate.loading);

  useEffect(() => {
    fetch("https://whatscookn.cloud:444/api")
      .then((response) => {
        if (response.status === 200) {
          setBackendStatus(Serverstate.up);
          setFrontendStatus(Serverstate.up);
          return response.json();
        } else if (response.status === 401 || response.status === 429) {
          setFrontendStatus(Serverstate.up);
          setBackendStatus(Serverstate.up);
        }
        else {
          setBackendStatus(Serverstate.unknown);
          setFrontendStatus(Serverstate.unknown);
        }
      }).then((body) => {
        if (body.message)
          setFrontendStatus(Serverstate.work)
      })
      .catch(() => {
        setFrontendStatus(Serverstate.unknown);
        setBackendStatus(Serverstate.down);
      });
  }, [])

  return (
    <div className="App">
      <h1>WhatsCookn Domain Status</h1>
      <ServerStatus serverName="Frontend Server" status={frontendStatus} />
      <ServerStatus serverName="Backend Server" status={backendStatus} />
    </div>
  );
}

export default App;
