// src/components/ServerStatus.js

import { useMemo } from 'react';

export enum Serverstate { 'up', 'down', 'work', 'unknown', 'loading' }

type Props = {
    serverName: string;
    status?: Serverstate;
};

const ServerStatus = (props: Props) => {
    const { serverName, status } = props;

    const visibleStatus = useMemo(() => {

        switch (status) {
            case Serverstate.up:
                return 'UP ✔️';

            case Serverstate.down:
                return 'DOWN ❌';

            case Serverstate.work:
                return '🚧 Under maintenance 🚧';

            case Serverstate.unknown:
                return '⚠️ Unknown status ⚠️ - We´re trying to figure it out 😅';

            case Serverstate.loading:
                return 'Checking...';

            default:
                return 'Checking...';
        }

    }, [status])


    return (
        <div className={`server-status ${(status === Serverstate.up) ? 'up' : 'down'}`}>
            <p>{serverName}</p>
            <p className="status">{visibleStatus}</p>
        </div>
    );
};

export default ServerStatus;
